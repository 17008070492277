import {DEPARTMENTS_MOCK} from './Department';

export class Teacher {

  code: string;
  id:number;
  name: string;
  surname: string;
  idDepartment: number;
}

export const TEACHERS_MOCK: Teacher[] = [
  { code: 'GDLH', id: 101, name: 'Gemma', surname: 'De las Heras', idDepartment: 20},
  { code: 'BMN', id: 102, name: 'Biel', surname: 'Maimó Noguera', idDepartment: 12},
  { code: 'CFD', id: 103, name: 'Catalina', surname: 'Femenies Duran', idDepartment: 14},
  { code: 'JRA', id: 104, name: 'Jaume', surname: 'Ramon Alejandro', idDepartment: 18},
  { code: 'PRA', id: 105, name: 'Pere', surname: 'Ramon Alejandro', idDepartment: 14}
];

