import { Component } from '@angular/core';
import {OktaAuthService} from '@okta/okta-angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hEUREKA';
  isAuthenticated: boolean;

  constructor(public oktaAuth: OktaAuthService, public router: Router) {
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );
  }

  async ngOnInit(){
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    // this.oktaAuth.isAuthenticated().then((auth) => {this.isAuthenticated = auth});
  }

  login (){
    this.oktaAuth.loginRedirect('/departments');
  }

  async logout (){
    await this.oktaAuth.logout();
    this.router.navigateByUrl('/');
    // this.oktaAuth.logout('/');
  }

}
