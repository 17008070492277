<div class="container pt-5 my-5 z-depth-1">
  <section class="p-md-3 mx-md-5 text-lg-left">
    <h2 class="text-center font-weight-bold mb-4 pb-1">Professorat</h2>
    <p class="text-center lead mb-5 pb-2 text-muted">Actius</p>
    <div class="row">
      <div class="col-lg-3 col-sm-6 mb-5" *ngFor="let teacher of professors">
        <div class="row d-flex align-items-center">
          <div class="col-5  w-100 white d-flex justify-content-center align-items-center">
            <ngx-avatar value={{teacher.code}}></ngx-avatar>

          </div>
          <div class="col-7">
            <h6 class="font-weight-bold pt-2">{{teacher.name}} {{teacher.surname}}</h6>
            <p class="text-muted">
                {{getDepartment(teacher.idDepartment)}}
            </p>
            <button mdbBtn type="button" color="default" outline="true" rounded="true" mdbWavesEffect [routerLink]="['/professor', teacher.id]">Detalls</button>

          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="col-lg-3 col-sm-6 mb-5" *ngFor="let d of dpts">
    {{d.id}} - {{d.name}} ({{d.initials}})
  </div>

  <div class="float-sm-right">
      <a mdbBtn floating="true" size="lg" gradient="peach" mdbWavesEffect (click)="addTeacher()">
        <mdb-icon fas icon="plus"></mdb-icon>
      </a>
  </div>
</div>
