<section class="section team-section">

  <!-- First row -->
  <div class="row">
    <!-- First column -->
    <div class="col-md-4 mb-1">

      <!--Card-->
      <mdb-card class="profile-card">

        <!--Avatar-->
        <div class="avatar z-depth-1-half mb-4 ">
          <ngx-avatar class="rounded-circle" size="150" value={{professor.code}}></ngx-avatar>
        </div>

        <mdb-card-body class="pt-0 mt-0">
          <!--Name-->
          <div class="text-center">
            <h3 class="mb-3 font-bold"><strong>{{professor.name}} {{professor.surname}}</strong></h3>
            <h5 class="font-bold blue-text mb-4">{{getDepartament(professor.idDepartment)}}</h5>
          </div>

          <ul class="striped list-unstyled">
            <li><strong>E-mail address:</strong> a.doe@example.com</li>

            <li><strong>Work Status</strong> Interim | Permanent</li>
          </ul>

        </mdb-card-body>

       <!-- <mdb-card-footer>
          <ul class="striped list-unstyled">
            <li><strong>Tutoria 3r ESO</strong></li>

            <li><strong>Comissió de Salut</strong></li>
          </ul>
        </mdb-card-footer>-->

      </mdb-card>
      <!--Card-->

      <!--Card-->
      <mdb-card cascade="true" narrower="true" class="mt-5">

        <!--Card image-->
        <div class="view view-cascade gradient-card-header blue-gradient">
          <h4 class="h4-responsive">Groups</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">

          <div class="chip">
            <img src="https://d30y9cdsu7xlg0.cloudfront.net/png/158907-200.png"
                 alt="Ask a question and accept an answer" class="img-fluid"> Tutoria 3r d'ESO
          </div>
          <div class="chip">
            <img src="https://www.hrglobalindia.com/wp-content/uploads/2015/05/icon3-e1431786925198.png"
                 alt="Provide non-wiki answers of 15 total score in 20 of top 40 tags" class="img-fluid"> Comissió Salut
          </div>
          <div class="chip">
            <img src="https://d30y9cdsu7xlg0.cloudfront.net/png/359329-200.png"
                 alt=" Accepted answer and score of 40 or more" class="img-fluid"> Extraescolars
          </div>
          <div class="chip">
            <img src="https://d30y9cdsu7xlg0.cloudfront.net/png/62983-200.png"
                 alt="Answer a question with score of 1 or more" class="img-fluid"> Formació moodle
          </div>

        </mdb-card-body>
        <!--/.Card content-->

      </mdb-card>
      <!--/.Card-->

    </div>
    <!-- /.First column -->

    <!-- Second column  -->
    <div class="col-md-8 mb-1" >
    <mdb-card cascade="true" narrower="true" class="z-depth-0">
  <!--Card image-->
    <div
      class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"
    >
      <div>
        <button
          type="button"
          mdbBtn
          outline="true"
          color="white"
          rounded="true"
          size="sm"
          class="px-2"
        >
          <mdb-icon fas icon="th-large" class="mt-0"></mdb-icon>
        </button>
        <button
          type="button"
          mdbBtn
          outline="true"
          color="white"
          rounded="true"
          size="sm"
          class="px-2"
        >
          <mdb-icon fas icon="columns" class="mt-0"></mdb-icon>
        </button>
      </div>

      <a href="" class="white-text mx-3">Weekly Schedule</a>

    </div>
    <!--/Card image-->

    <div class="px-4">
      <div class="table-wrapper">
        <!--Table-->
        <table class="table table-hover mb-0">
          <!--Table head-->
          <thead>
          <tr>

            <th class="th-lg">
              <a>
                 Hour
              </a>
            </th>
            <th class="th-lg">
              <a>Monday</a>
            </th>
            <th class="th-lg">
              <a>Tuesday</a>
            </th>
            <th class="th-lg">
              <a>Wednesday</a>
            </th>
            <th class="th-lg">
              <a>Thursday</a>
            </th>
            <th class="th-lg">
              <a>Friday</a>
            </th>
          </tr>
          </thead>
          <!--Table head-->

          <!--Table body-->
          <tbody>
          <tr *ngFor="let row of schedule">
            <td>{{ row.time }}</td>
            <td>{{ row.monday }}</td>
            <td>{{ row.tuesday }}</td>
            <td>{{ row.wednesday }}</td>
            <td>{{ row.thursday }}</td>
            <td>{{ row.friday }}</td>
          </tr>
          </tbody>
          <!--Table body-->
        </table>
        <!--Table-->
      </div>


    </div>
    </mdb-card>
    </div>

  </div>


</section>

