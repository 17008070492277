import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProfessorsService} from '../../services/professors.service';
import {Department, DEPARTMENTS_MOCK} from '../../model/Department';
import {Teacher} from '../../model/Teacher';

@Component({
  selector: 'app-detail-departament',
  templateUrl: './detail-departament.component.html',
  styleUrls: ['./detail-departament.component.scss']
})
export class DetailDepartamentComponent implements OnInit {


  departament: Department;
  professorsDpt: Teacher[];

  constructor(private route: ActivatedRoute, private professorsService: ProfessorsService) { }



  ngOnInit(): void {
    const dptIDFromRoute = this.route.snapshot.paramMap.get('departamentID');
    this.departament=DEPARTMENTS_MOCK.find(dpt => dpt.id === Number(dptIDFromRoute));

    this.professorsDpt=this.professorsService.getProfessorsByDptId(this.departament.id);


  }

  getProfessorsDpt(){
    return
  }



}
