import { Component, OnInit } from '@angular/core';
import {TEACHERS_MOCK} from '../../model/Teacher';
import {DEPARTMENTS_MOCK} from '../../model/Department';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('Holaaa DASHBOARD');
  }

  getNumberOfTeachers(){
    return TEACHERS_MOCK.length;
  }

  getNumberOfDepartments() {
    return DEPARTMENTS_MOCK.length;
  }

  getDate() {
    const today = new Date();

    return String(today.getDate()).padStart(2, '0')+' / ' + today.getMonth()+1 +' / '+ today.getFullYear();



  }

  getTime() {
    const today = new Date();

    return String(today.getHours()+':'+today.getMinutes());
  }
}
