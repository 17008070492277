import { Injectable } from '@angular/core';
import {Teacher, TEACHERS_MOCK} from '../model/Teacher';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfessorsService {

  constructor() { }

  getProfessors(): Observable<Teacher[]> {
    return of(TEACHERS_MOCK);
  }

  getProfessor(id: number): Observable<Teacher> {
    return of(TEACHERS_MOCK.find(professors => professors.id === id));

  }

  getProfessorsByDptId(idDpt: number): Teacher[]{
    return (TEACHERS_MOCK.filter(professors => professors.idDepartment===idDpt));

  }

}
