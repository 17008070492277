export class Department {

  id: number;
  owner: string;
  initials: string;
  name: string;
  email: string;
  comments: string;

}

export const DEPARTMENTS_MOCK: Department[] = [
  { id: 11, initials:'CAT',  name: 'Català', owner:'null', email:'null',comments:'null' },
  { id: 12, initials:'CAS', name: 'Castellà', owner:'null', email:'null',comments:'null'  },
  { id: 13, initials:'LES', name: 'Llengues estrangeres', owner:'null', email:'null',comments:'null'  },
  { id: 14, initials:'MAT',name: 'Matemàtiques', owner:'null', email:'null',comments:'null'  },
  { id: 15, initials:'BiG',name: 'Biologia i Geologia', owner:'null', email:'null',comments:'null'  },
  { id: 16, initials:'GiH',name: 'Geografia i història', owner:'null', email:'null',comments:'null'  },
  { id: 17, initials:'TEC',name: 'Tecnologia', owner:'null', email:'null',comments:'null'  },
  { id: 18, initials:'INF',name: 'Informàtica' , owner:'null', email:'null',comments:'null' },
  { id: 19, initials:'EF',name: 'Educació Física', owner:'null', email:'null',comments:'null'  },
  { id: 20, initials:'MUS',name: 'Música' , owner:'null', email:'null',comments:'null' },
  { id: 21, initials:'EPV',name: 'Educació Plàstica i Visual', owner:'null', email:'null',comments:'null'  },
  { id: 22, initials:'FIL',name: 'Filosofia' , owner:'null', email:'null',comments:'null' },
  { id: 23, initials:'ECO',name: 'Economia', owner:'null', email:'null',comments:'null'  },
  { id: 24, initials:'OR',name: 'Orientació', owner:'null', email:'null',comments:'null'  }

];


