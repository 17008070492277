
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';

import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { MenuComponent } from './components/menus/menu/menu.component';
import { ProfessorsComponent } from './components/professors/professors.component';
import { MateriesComponent } from './components/materies/materies.component';
import { CursosComponent } from './components/cursos/cursos.component';

import {AppRoutingModule} from './app-routing.module';
import { DepartamentsComponent } from './components/departaments/departaments.component';
import { LoginComponent } from './components/login/login.component';

import { ReactiveFormsModule} from '@angular/forms';
import { CheckboxModule, ChartsModule, ChartSimpleModule , ButtonsModule, WavesModule, CardsModule, ProgressbarModule } from 'ng-uikit-pro-standard';
import { AvatarModule } from 'ngx-avatar';
import { DetailProfessorComponent } from './components/detail-professor/detail-professor.component';
import { DetailDepartamentComponent } from './components/detail-departament/detail-departament.component';
import {OKTA_CONFIG, OktaAuthModule} from '@okta/okta-angular';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { ProtectedComponent } from './components/protected/protected.component';
import {LandingComponent} from './components/landing/landing.component';
import { CalculadoraOposicionsComponent } from './components/others/calculadora-oposicions/calculadora-oposicions.component';
import { NumeroAzarComponent } from './components/others/numero-azar/numero-azar.component';
import { IntroComponent } from './components/intro/intro.component';
import { SorteoListaComponent } from './components/others/sorteo-lista/sorteo-lista.component';
import { PanelComponent } from './components/shared/panel/panel.component';
import { SorteoNumeroComponent } from './components/others/sorteo-numero/sorteo-numero.component';
import { GruposAlAzarComponent } from './components/others/grupos-al-azar/grupos-al-azar.component';


  const config = {
    issuer: 'https://dev-6720933.okta.com/oauth2/default',
    redirectUri: window.location.origin + '/implicit/callback',
    clientId: '0oa36krvesYi5HeCu5d6',
    pkce: true
  }

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ProfessorsComponent,
    MateriesComponent,
    CursosComponent,
    DepartamentsComponent,
    LoginComponent,
    DetailProfessorComponent,
    DetailDepartamentComponent,
    DashboardComponent,
    LoginPageComponent,
    ProtectedComponent,
    LandingComponent,
    CalculadoraOposicionsComponent,
    NumeroAzarComponent,
    IntroComponent,
    SorteoListaComponent,
    PanelComponent,
    SorteoNumeroComponent,
    GruposAlAzarComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CardsModule,
    ButtonsModule,
    WavesModule,
    ChartsModule,
    CheckboxModule,
    ChartSimpleModule,
    ProgressbarModule,
    AvatarModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    OktaAuthModule.initAuth({
      issuer: 'https://dev-6720933.okta.com/oauth2/default',
      redirectUri: 'http://localhost:4200/loginPage/callback',
      clientId: '0oa36krvesYi5HeCu5d6'
    }),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    })
  ],
  providers: [MDBSpinningPreloader,
    {provide: OKTA_CONFIG, useValue:config}],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule {
  constructor() {
  }
}
