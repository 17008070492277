<section>

  <!--Section heading-->
  <h2 class="text-center h1 py-5">
    <strong>Elige el sorteo a realizar</strong>
  </h2>

  <!--Section description-->
  <p class="grey-text pb-5 text-center">Elige el sorteo que más se adapte a tus necesidades. Irán apareciendo más tipos de sorteos.</p>

  <!--Grid row-->
  <div class="row text-center">

    <!--Grid column-->
    <div class="col-lg-4 col-md-12 mb-4">

      <!--Featured image-->
      <div class="view overlay rounded z-depth-1 waves-light" mdbWavesEffect>
        <img src="../../../assets/images/landing1.png" class="img-fluid" alt="Sample project image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!--Excerpt-->
      <mdb-card-body class="mt-3">
        <h4>
          <strong>Números al azar</strong>
        </h4>
        <p class="grey-text">Muestra un número o varios números al azar con o sin reposición
        </p>
        <a mdbBtn color="indigo" size="sm" class="waves-light" mdbWavesEffect routerLink="/numero-azar">
          <mdb-icon far icon="clone" class="left"></mdb-icon> Número al azar</a>
      </mdb-card-body>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 mb-4">

      <!--Featured image-->
      <div class="view overlay rounded z-depth-1  waves-light" mdbWavesEffect>
        <img src="../../../assets/images/landing3.png" class="img-fluid" alt="Sample project image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!--Excerpt-->
      <mdb-card-body class="mt-3">
        <h4>
          <strong>Sorteo de premios</strong>
        </h4>
        <p class="grey-text">Introduce una lista de nombres o equipos y asignales un premio de una lista de premios.
        </p>
        <a mdbBtn color="indigo" size="sm" class="waves-light" mdbWavesEffect routerLink="/sorteo-lista">
          <mdb-icon far icon="clone" class="left"></mdb-icon> Sorteo premios</a>
      </mdb-card-body>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 mb-4">

      <!--Featured image-->
      <div class="view overlay rounded z-depth-1  waves-light" mdbWavesEffect>
        <img src="../../../assets/images/landing2.png" class="img-fluid" alt="Sample project image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!--Excerpt-->
      <mdb-card-body class="mt-3">
        <h4>
          <strong>Oposición</strong>
        </h4>
        <p class="grey-text">Simula el sorteo de unas oposiciones. Se adapta a cualquier tipo de oposición.
          Conoce las probabilidades en función de los temas estudiados.
        </p>
        <a mdbBtn color="indigo" size="sm" class="waves-light" mdbWavesEffect routerLink="/calculadora-oposiciones">
          <mdb-icon far icon="clone" class="left"></mdb-icon> Simular oposición</a>
      </mdb-card-body>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 mb-4">

      <!--Featured image-->
      <div class="view overlay rounded z-depth-1  waves-light" mdbWavesEffect>
        <img src="https://mdbootstrap.com/img/Photos/Others/images/88.jpg" class="img-fluid" alt="Sample project image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!--Excerpt-->
      <mdb-card-body class="mt-3">
        <h4>
          <strong>Sorteo</strong>
        </h4>
        <p class="grey-text">Introduce una lista de nombres o equipos y muestra un número de equipos premiados.
        </p>
        <a mdbBtn color="indigo" size="sm" class="waves-light" mdbWavesEffect routerLink="/sorteo-numero">
          <mdb-icon far icon="clone" class="left"></mdb-icon> Simular oposición</a>
      </mdb-card-body>

    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

</section>
<!--/Projects section v.1-->
