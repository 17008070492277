
<header class="h-100">
  <mdb-navbar SideClass="navbar navbar-dark ">
    <mdb-navbar-brand>
      <a class="navbar-brand" href="#">
        <img src="../../../../assets/images/logo1.png" height="30" class="d-inline-block align-top" alt="">
        sorteo.online
      </a>
    </mdb-navbar-brand>
  </mdb-navbar>
</header>

<main class="text-center py-5 mt-3">
    <router-outlet></router-outlet>
</main>
