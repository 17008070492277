import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ProfessorsComponent} from './components/professors/professors.component';
import {MateriesComponent} from './components/materies/materies.component';
import {CursosComponent} from './components/cursos/cursos.component';
import {DepartamentsComponent} from './components/departaments/departaments.component';
import {LoginComponent} from './components/login/login.component';
import {DetailProfessorComponent} from './components/detail-professor/detail-professor.component';
import {DetailDepartamentComponent} from './components/detail-departament/detail-departament.component';
import {OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {ProtectedComponent} from './components/protected/protected.component';
import {LandingComponent} from './components/landing/landing.component';
import {CalculadoraOposicionsComponent} from './components/others/calculadora-oposicions/calculadora-oposicions.component';
import {NumeroAzarComponent} from "./components/others/numero-azar/numero-azar.component";
import {SorteoListaComponent} from "./components/others/sorteo-lista/sorteo-lista.component";
import {SorteoNumeroComponent} from "./components/others/sorteo-numero/sorteo-numero.component";
import {GruposAlAzarComponent} from "./components/others/grupos-al-azar/grupos-al-azar.component";

export function onAuthRequired (oktaAuth, injector) {
  const router = injector.get(Router);
  router.navigate(['loginPage']);
}

const routes: Routes = [
  { path: '', redirectTo: '/landing', pathMatch: 'full'},
  { path: 'landing', component: LandingComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate:[OktaAuthGuard], data: {onAuthRequired}},
  { path: 'professors', component: ProfessorsComponent, canActivate:[OktaAuthGuard], data: {onAuthRequired} },
  { path: 'cursos', component: CursosComponent },
  { path: 'materies', component: MateriesComponent },
  { path: 'departaments', component: DepartamentsComponent, canActivate:[OktaAuthGuard], data: {onAuthRequired} },
  { path: 'login', component: LoginComponent },
  { path: 'professor/:professorId', component: DetailProfessorComponent },
  { path: 'departament/:departamentID', component: DetailDepartamentComponent},
  { path: 'loginPage', component: LoginPageComponent},
  { path: 'loginPage/callback', component: OktaCallbackComponent},
  { path: 'protected', component: ProtectedComponent, canActivate:[OktaAuthGuard], data: {onAuthRequired}},
  { path: 'implicit/callback', component: OktaCallbackComponent},
  { path: 'calculadora-oposiciones', component: CalculadoraOposicionsComponent},
  { path: 'numero-azar' , component: NumeroAzarComponent},
  { path: 'sorteo-lista', component: SorteoListaComponent},
  { path: 'sorteo-numero', component: SorteoNumeroComponent},
  { path: 'grupos-al-azar', component: GruposAlAzarComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
