<!-- Main navigation -->
<header>
  <!--Navbar-->
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar">
    <mdb-navbar-brand>
      <a class="logo navbar-brand waves-light" mdbWavesEffect href="#"><strong>MDB</strong></a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active waves-light" mdbWavesEffect>
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" href="#">Profile</a>
        </li>
      </ul>
      <form class="form-inline">
        <div class="md-form my-0">
          <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
        </div>
      </form>
    </links>
  </mdb-navbar>
  <!-- Full Page Intro -->
  <div class="view" style="background-image:  url('../../../assets/images/background-3729817_1920.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <!-- Mask & flexbox options-->
    <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
      <!-- Content -->
      <div class="container">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-6 white-text text-center text-md-left mt-xl-5 mb-5 wow fadeInLeft" data-wow-delay="0.3s">
            <h1 class="h1-responsive font-weight-bold mt-sm-5">Make purchases with our app </h1>
            <hr class="hr-light">
            <h6 class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem repellendus quasi
              fuga nesciunt
              dolorum nulla magnam veniam sapiente, fugiat! Commodi sequi non animi ea dolor molestiae
              iste.</h6>
            <a mdbBtn color="white" mdbWavesEffect>Download</a>
            <a mdbBtn color="white" outline="true" mdbWavesEffect>Learn more</a>
          </div>
          <!--Grid column-->
          <!--Grid column-->
          <div class="col-md-6 col-xl-5 mt-xl-5 wow fadeInRight" data-wow-delay="0.3s">
            <img src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new.png" alt="" class="img-fluid">
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
  </div>
  <!-- Full Page Intro -->
</header>
<!-- Main navigation -->
<!--Section: Team v.4-->
<section class="section pb-3">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center my-5">Our amazing team</h2>
  <!-- Section description -->
  <p class="grey-text text-center w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur
    adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas
    nostrum quisquam eum porro a pariatur veniam.</p>

  <div class="row">

    <!--Grid column-->
    <div class="col-lg-4 col-md-12">

      <mdb-card-rotating #cards1>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(132).jpg" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <img src="https://mdbootstrap.com/img/Photos/Avatars/img (10).jpg" class="rounded-circle img-responsive">
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Maria Kate</h4>
            <p>Photographer</p>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-1" (click)="cards1.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to rotate</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>About me</h4>
          <hr>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime quae, dolores dicta. Blanditiis
            rem
            amet repellat, dolores nihil quae in mollitia asperiores ut rerum repellendus, voluptatum eum,
            officia
            laudantium quaerat?
          </p>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="icons-sm fb-ic">
                <mdb-icon fab icon="facebook-f"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm pin-ic">
                <mdb-icon fab icon="pinterest"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm ins-ic">
                <mdb-icon fab icon="instagram"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm tw-ic">
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-1" (click)="cards1.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-12">

      <mdb-card-rotating #cards2>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(73).jpg" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <img src="https://mdbootstrap.com/img/Photos/Avatars/img (20).jpg" class="rounded-circle img-responsive">
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Anna Deynah</h4>
            <p>Web Designer</p>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-1" (click)="cards2.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to rotate</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>About me</h4>
          <hr>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime quae, dolores dicta. Blanditiis
            rem
            amet repellat, dolores nihil quae in mollitia asperiores ut rerum repellendus, voluptatum eum,
            officia
            laudantium quaerat?
          </p>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="icons-sm fb-ic">
                <mdb-icon fab icon="facebook-f"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm tw-ic">
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm gplus-ic">
                <mdb-icon fab icon="google-plus"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm dribbble-ic">
                <mdb-icon fab icon="dribbble"></mdb-icon>
              </a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-1" (click)="cards2.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-12">

      <mdb-card-rotating #cards3>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(14).jpg" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <img src="https://mdbootstrap.com/img/Photos/Avatars/img (8).jpg" class="rounded-circle img-responsive">
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>John Doe</h4>
            <p>Front-end Developer</p>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-1" (click)="cards3.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to rotate</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>About me</h4>
          <hr>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime quae, dolores dicta. Blanditiis
            rem
            amet repellat, dolores nihil quae in mollitia asperiores ut rerum repellendus, voluptatum eum,
            officia
            laudantium quaerat?
          </p>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="icons-sm gplus-ic">
                <mdb-icon fab icon="google-plus"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm fb-ic">
                <mdb-icon fab icon="facebook-f"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm git-ic">
                <mdb-icon fab icon="github"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="icons-sm li-ic">
                <mdb-icon fab icon="linkedin-in"></mdb-icon>
              </a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-1" (click)="cards3.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

  </div>

</section>
<!--Section: Team v.4-->
