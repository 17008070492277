import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-sorteo-numero',
  templateUrl: './sorteo-numero.component.html',
  styleUrls: ['./sorteo-numero.component.scss']
})
export class SorteoNumeroComponent implements OnInit {

  hideElement: boolean = true;
  resultatsPremis: string[] = [];
  resultatsGuanyadors: string[] = [];
  result: number;
  names: string[] = [];
  awards: string[] = [];

  gradientForm: FormGroup;
  gradientForm2: FormGroup;
  gradientForm3: FormGroup;

  showText = false;


  constructor(public fb: FormBuilder, private toastrService: ToastService, private el: ElementRef, private renderer: Renderer2) {
    this.gradientForm = new FormGroup({
      name: new FormControl()
    })

    this.gradientForm2 = fb.group({
      nAwards: [1, [Validators.required]],
      reemplazo: [false, Validators.required]
    });


    this.gradientForm3 = new FormGroup({
      premiosAleatorios: new FormControl(),
      reemplazo: new FormControl()
    })

  }

  ngOnInit(): void {
  }

  trunc (x, posiciones = 0) {
    const s = x.toString();
    const l = s.length;
    const decimalLength = s.indexOf('.') + 1;

    if (l - decimalLength <= posiciones){
      return x;
    }
    const isNeg  = x < 0;
    const decimal =  x % 1;
    const entera  = isNeg ? Math.ceil(x) : Math.floor(x);
    const decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    );
    const finalNum = entera +
      ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1));
    return finalNum;
  }


  calcule() {
    if (!this.disabledCalcule()) {

      let i = 0;
      let namesCopy: string[] = [];

      namesCopy = [...this.names];
      this.resultatsGuanyadors.splice(0, this.resultatsGuanyadors.length);

      for (i = 0; i < this.gradientForm2.get('nAwards').value; i++) {
        let nName = Math.random() * namesCopy.length;
        nName = this.trunc(nName);
        this.resultatsGuanyadors.push(namesCopy[nName].toString());
        if (!this.gradientForm2.get('reemplazo')) {
          this.resultatsGuanyadors.splice(nName, 1);
        }
      }
    }
  }

  clear() {
    this.gradientForm.reset();
    this.gradientForm2.reset();
    this.resultatsGuanyadors.splice(0,this.resultatsGuanyadors.length);
    this.names.splice(0, this.names.length);
    this.gradientForm2 = this.fb.group({
      nAwards: [1, [Validators.required]],
      reemplazo: [false, Validators.required]
    });
  }


  keyDownFunction(event: KeyboardEvent) {
    if (event.code==='Enter' && !this.gradientForm.get('name').value.isBlank){
      this.names.push(this.gradientForm.get('name').value)
      this.gradientForm.reset();
    }
  }


  delete(i: number) {
    this.names.splice(i,1);
    console.log(i);
  }


  disabledCalcule() {
    let r=true;

    if (this.names.length==0) {
      const options = { closeButton: true, extendedTimeOut: 2000,  tapToDismiss: false, progressBar: true, timeOut: 3000, opacity:0.95};
      this.toastrService.warning('Debes introducir almenos un participante','Alerta',  options);
    }
    else  if (this.names.length<this.gradientForm2.get('nAwards').value && !this.gradientForm2.get('reemplazo').value ){
      const options = { closeButton: true,  tapToDismiss: false, progressBar: true, timeOut: 3000, opacity:0.95};
      this.toastrService.warning('Hay menos jugadores que premios a repartir. Introduce más jugadores, elimina premios o activa la opción de reemplazo','Alerta',  options);
    }
    else r=false;


    return r;

  }

  calculeMax() {
    if (this.names.length==0) return 1
    else return this.names.length;
  }
}
