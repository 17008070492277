import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-sorteo-lista',
  templateUrl: './sorteo-lista.component.html',
  styleUrls: ['./sorteo-lista.component.scss']
})
export class SorteoListaComponent implements OnInit {

  hideElement: boolean = true;
  resultatsPremis: string[] = [];
  resultatsGuanyadors: string[] = [];
  result: number;
  names: string[] = [];
  awards: string[] = [];

  gradientForm: FormGroup;
  gradientForm2: FormGroup;
  gradientForm3: FormGroup;

  showText = false;


  constructor(public fb: FormBuilder, private toastrService: ToastService, private el: ElementRef, private renderer: Renderer2) {
    this.gradientForm = new FormGroup({
      name: new FormControl()
    })

    this.gradientForm2 = new FormGroup({
      award: new FormControl()
    })

    this.gradientForm3 = new FormGroup({
      premiosAleatorios: new FormControl(),
      reemplazo: new FormControl()
    })

  }

  ngOnInit(): void {
    const cardHeight = 500;
    const cardWrapper = this.el.nativeElement.querySelectorAll('.card-wrapper');
    const cardBody = cardWrapper[0].querySelector('.card-body');


    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(() => {
        const updatedHeight = cardHeight + cardBody.offsetHeight;
        this.renderer.setStyle(cardWrapper[0], 'height', updatedHeight + 'px');
      });
    });

    observer.observe(cardBody, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    setTimeout(() => {
      this.showText = true;
    }, 2000);
  }

  trunc (x, posiciones = 0) {
    const s = x.toString();
    const l = s.length;
    const decimalLength = s.indexOf('.') + 1;

    if (l - decimalLength <= posiciones){
      return x;
    }
    const isNeg  = x < 0;
    const decimal =  x % 1;
    const entera  = isNeg ? Math.ceil(x) : Math.floor(x);
    const decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    );
    const finalNum = entera +
      ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1));
    return finalNum;
  }


  calcule() {

    if (this.disabledCalcule()){

    }
    else {
      let i=0;
      let namesCopy: string[] = [];
      let awardsCopy: string[] = [];

      namesCopy=[...this.names];
      awardsCopy=[...this.awards];
      this.resultatsGuanyadors.splice(0, this.resultatsGuanyadors.length);
      this.resultatsPremis.splice(0, this.resultatsPremis.length);


      let awardsToAssign = awardsCopy.length;
      while (i<awardsToAssign){
        let nName=Math.random()*namesCopy.length;
        nName = this.trunc(nName);

        this.resultatsGuanyadors.push(namesCopy[nName].toString());
        // this.resultatsPremis.push(awardsCopy[i].toString());

        console.log(this.gradientForm3.get('premiosAleatorios').value);
        if (this.gradientForm3.get('premiosAleatorios').value){
          let nAward=Math.random()*awardsCopy.length;
          nAward = this.trunc(nAward);
          console.log('Eliminant el: ',nAward, ' de ', awardsCopy);
          this.resultatsPremis.push(awardsCopy[nAward].toString());
          awardsCopy.splice(nAward,1);
          console.log(awardsCopy);
        } else {
          this.resultatsPremis.push(awardsCopy[i].toString());
        }
        if (!this.gradientForm3.get('reemplazo').value){
          namesCopy.splice(nName,1);
        }
        i++;
      }
    }

  }

  clear() {
    this.gradientForm.reset();
    this.gradientForm2.reset();
    this.gradientForm3.reset();
    this.resultatsPremis.splice(0,this.resultatsPremis.length);
    this.resultatsGuanyadors.splice(0,this.resultatsGuanyadors.length);
    this.awards.splice(0,this.awards.length);
    this.names.splice(0, this.names.length);
  }


  keyDownFunction(event: KeyboardEvent) {
    if (event.code==='Enter' && !this.gradientForm.get('name').value.isBlank){
      this.names.push(this.gradientForm.get('name').value)
      this.gradientForm.reset();
    }
  }

  keyDownFunctionAwards(event: KeyboardEvent) {
    if (event.code==='Enter' && !this.gradientForm2.get('award').value.isBlank){
      this.awards.push(this.gradientForm2.get('award').value)
      this.gradientForm2.reset();
    }
  }

  delete(i: number) {
    this.names.splice(i,1);
    console.log(i);
  }

  deleteAward(i: number) {
    this.awards.splice(i,1);
    console.log(i);
  }

  disabledCalcule() {
    let r=true;
  if (this.names.length==0 || this.awards.length==0) {
      const options = { closeButton: true, extendedTimeOut: 2000,  tapToDismiss: false, progressBar: true, timeOut: 3000, opacity:0.95};
      this.toastrService.warning('Debes introducir almenos un participantes y un premio','Alerta',  options);
    } else   if (this.awards.length>this.names.length && !this.gradientForm3.get('reemplazo').value ){
    const options = { closeButton: true,  tapToDismiss: false, progressBar: true, timeOut: 3000, opacity:0.95};
    this.toastrService.warning('Hay menos jugadores que premios a repartir. Introduce más jugadores, elimina premios o activa la opción de reemplazo','Alerta',  options);
  }
  else r=false;
  return r;

  }
}
