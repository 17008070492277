<p>login works!</p>
<button type="button" mdbBtn color="default" rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>Launch Modal
</button>


<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <!--Content-->
    <div class="modal-content form-elegant">
      <!--Header-->
      <div class="modal-header text-center">
        <h3 class="modal-title w-100 dark-grey-text font-weight-bold my-3" id="myModalLabel">
          <strong>Sign in</strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--Body-->
      <div class="modal-body mx-4">
        <!--Body-->
        <div class="md-form mb-5">
          <input type="email" id="Form-email1" class="form-control" mdbInput mdbValidate
                 [formControl]="modalFormElegantEmail">
          <label for="Form-email1">Your email</label>
          <mdb-error
            *ngIf="modalFormElegantEmail.invalid && (modalFormElegantEmail.dirty || modalFormElegantEmail.touched)">
            Input invalid
          </mdb-error>
          <mdb-success
            *ngIf="modalFormElegantEmail.valid && (modalFormElegantEmail.dirty || modalFormElegantEmail.touched)">Input
            valid
          </mdb-success>
        </div>

        <div class="md-form pb-3">
          <input type="password" id="Form-pass1" class="form-control" mdbInput mdbValidate
                 [formControl]="modalFormElegantPassword">
          <label for="Form-pass1">Your password</label>
          <mdb-error
            *ngIf="modalFormElegantPassword.invalid && (modalFormElegantPassword.dirty || modalFormElegantPassword.touched)">
            Input invalid
          </mdb-error>
          <mdb-success
            *ngIf="modalFormElegantPassword.valid && (modalFormElegantPassword.dirty || modalFormElegantPassword.touched)">
            Input valid
          </mdb-success>
          <p class="font-small blue-text d-flex justify-content-end">Forgot
            <a href="#" class="blue-text ml-1"> Password?</a>
          </p>
        </div>

        <div class="text-center mb-3">
          <button type="button" mdbBtn gradient="blue" block="true" rounded="true" class="z-depth-1a waves-light"
                  mdbWavesEffect>Sign in
          </button>
        </div>
        <p class="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2"> or Sign in
          with:</p>

        <div class="row my-3 d-flex justify-content-center">
          <!--Facebook-->
          <button type="button" mdbBtn color="white" rounded="true" class="mr-md-3 z-depth-1a waves-light"
                  mdbWavesEffect>
            <mdb-icon fab icon="facebook-f" class="text-center"></mdb-icon>
          </button>
          <!--Twitter-->
          <button type="button" mdbBtn color="white" rounded="true" class="mr-md-3 z-depth-1a waves-light"
                  mdbWavesEffect>
            <mdb-icon fab icon="twitter"></mdb-icon>
          </button>
          <!--Google +-->
          <button type="button" mdbBtn color="white" rounded="true" class="z-depth-1a waves-light"
                  mdbWavesEffect>
            <mdb-icon fab icon="google-plus"></mdb-icon>
          </button>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer mx-5 pt-3 mb-1">
        <p class="font-small grey-text d-flex justify-content-end">Not a member?
          <a href="#" class="blue-text ml-1"> Sign Up</a>
        </p>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
