import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart} from '@angular/router';

import { OktaAuthService } from '@okta/okta-angular';
import * as OktaSignIn from '@okta/okta-signin-widget';

@Component({
  selector: 'app-secure',
  template: `
    <!-- Container to inject the Sign-In Widget -->
    <div id="okta-signin-container"></div>

  `
})
export class LoginPageComponent implements OnInit {
  signIn;
  widget = new OktaSignIn({
    baseUrl: 'https://dev-6720933.okta.com',
    authParams: {
      pkce: true
    },  features: {
      // Used to enable registration feature on the widget.
      // https://github.com/okta/okta-signin-widget#feature-flags
      registration: true // REQUIRED
    }
  });

  constructor(oktaAuth: OktaAuthService, router: Router) {
    this.signIn = oktaAuth;

    // Show the widget when prompted, otherwise remove it from the DOM.
    router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        switch(event.url) {
          case '/loginPage':
            break;
          case '/protected':
          case '/departaments':
            break;
          default:
            this.widget.remove();
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.widget.renderEl({
        el: '#okta-signin-container'},
      (res) => {
        if (res.status === 'SUCCESS') {
           console.log('Connected');
          this.signIn.loginRedirect('/dashboard');
          // Hide the widget
          this.widget.hide();
        }
      },
      (err) => {
        throw err;
      }
    );
  }
}
