import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-numero-azar',
  templateUrl: './numero-azar.component.html',
  styleUrls: ['./numero-azar.component.scss']
})
export class NumeroAzarComponent implements OnInit {

  constructor(public fb: FormBuilder) {
    this.gradientForm = fb.group({
      min: [1, [Validators.required]],
      max: [100, Validators.required],
      bolas: [1, Validators.required],
      reemplazo: [false, Validators.required]
    });
  }

  result = 0;
  gradientForm: FormGroup;
  resultats = [];

  ngOnInit(): void {
    this.resultats[0]=0;
  }

  getNMin(){
    return this.gradientForm.get('min').value;
  }
  getNMax(){
    return this.gradientForm.get('max').value;
  }
  getNBolas(){
    return this.gradientForm.get('bolas').value;
  }
  getReemplazo(){
    return this.gradientForm.get('reemplazo').value;
  }

  trunc (x, posiciones = 0) {
    const s = x.toString();
    const l = s.length;
    const decimalLength = s.indexOf('.') + 1;

    if (l - decimalLength <= posiciones){
      return x;
    }
    const isNeg  = x < 0;
    const decimal =  x % 1;
    const entera  = isNeg ? Math.ceil(x) : Math.floor(x);
    const decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    );
    const finalNum = entera +
      ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1));
    return finalNum;
  }

  calcule() {
    this.resultats.splice(0,this.resultats.length);
    let i=0;
    let number;
    while (i<this.getNBolas()){
      number= Math.random()*(this.getNMax()-this.getNMin()+1);
      number= this.trunc(number);
      number+=this.getNMin();
      this.result=1;
      if (this.getReemplazo()){
        this.resultats.push(number);
        i++;
      }
      else if (!this.resultats.includes(number)) {
        this.resultats.push(number);
        i=i+1;
      }
    }
  }

  clear() {
    this.resultats.splice(0,this.resultats.length);
    this.result=0;
    this.gradientForm.reset();
    this.gradientForm = this.fb.group({
      min: [1, [Validators.required]],
      max: [100, Validators.required],
      bolas: [1, Validators.required],
      reemplazo: [false, Validators.required]
    });
  }

  order() {
    this.resultats.sort(function (a,b) {return a-b});
  }
}
