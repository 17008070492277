<div >
<section class="m-5">

  <!--Card-->
  <mdb-card cascade="true" class="narrower">

    <!--Section: Chart-->
    <section>

      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col mr-0">

          <!--Card image-->
          <div class="view view-cascade gradient-card-header prova lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Calculadora d'oposicions</h2>
          </div>
          <!--/Card image-->

          <!--Card content-->
          <mdb-card-body cascade="true" class="pb-0">
            <div class="view view-cascade gradient-card-header  lighten-1 text-white mt-2 mb-4">

            <mdb-card-body class="row pt-3">
              <div class="col-12">
                <form [formGroup]="gradientForm">
                  <div class="md-form">
                    <input type="number" id="temario" formControlName="temario" class="form-control" mdbInput>
                    <label for="temario">Temas del temario</label>
                  </div>
                  <div class="md-form">
                    <input type="number" id="estudiados" formControlName="estudiados" class="form-control" mdbInput>
                    <label for="estudiados">Estuadiados</label>
                    <mdb-error *ngIf="getNEstudiados()>getNTemario()">No puedes haber estudiado más temas que los que hay en el temario</mdb-error>

                  </div>
                  <div class="md-form">
                    <input type="number" id="bolas" formControlName="bolas" class="form-control" mdbInput>
                    <label for="bolas">Bolas</label>
                    <mdb-error *ngIf="getNBolas()>10">Has superado el número máximo de bolas que permite la aplicación (10)</mdb-error>

                  </div>
                </form>
              </div>
            </mdb-card-body>

              <mdb-card-footer class="bg-white border-0">
                <button mdbBtn color="default" rounded="false" [disabled]="getNEstudiados()>=getNTemario() || getNBolas()>10" (click)="calcule();">Calcular</button>
              </mdb-card-footer>
            </div>
          </mdb-card-body>

          <div class="view view-cascade gradient-card-header prova2 lighten-1 text-white mt-1 mb-4" *ngIf="result!==0">
            <h2 class="h2-responsive">Ejemplo de sorteo</h2>
          </div>

            <div class="view view-cascade gradient-card-header lighten-1 text-white mb-4" *ngIf="result!==0">

          <div class="text-center d-inline-block justify-content-between align-items-center align-middle" *ngFor="let n of randomThemes"  >
            <a mdbBtn rounded="true" outline="true" size="lg" gradient="sunny-morning">{{n}} </a>
          </div>
            <div>
              <button mdbBtn color="default" rounded="false" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                      (click)="calcRandom();">Resortear</button>
            </div>
          </div>

          <div class="mb-2"></div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-xl-7 col-lg-12 my-0">

          <!--Card image-->
          <div class="view view-cascade gradient-card-header prova2 mb-4" >
            <h2 class="h2-responsive mb-1">Probabilidades</h2>
          </div>
          <mdb-card-body cascade="true" class="pb-0" *ngIf="result==0">
            <div class="view view-cascade gradient-card-header text-reset  lighten-1  mb-4">
              <mdb-card-body class="row pt-3">
                <p class="text-prova">Introduce los datos de la prueba para calcular las posibilidades</p>

              </mdb-card-body>
            </div>
          </mdb-card-body>

          <div class="view view-cascade gradient-card-header sunny-morning-gradient " *ngIf="result!==0" >
            <h1 class="view display-2">{{result}}% <h5>Almenos un tema </h5></h1>
          </div>

          <div class="view  gradient-card-header mx-3  text-reset " *ngIf="result!==0" >

            <ul class="container">
              <div class="row mt-4">
                  <canvas mdbChart
                          [chartType]="chartType"
                          [datasets]="chartDatasets"
                          [labels]="chartLabels"
                          [colors]="chartColors"
                          [options]="chartOptions"
                          [legend]="true">
                  </canvas>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div class="float-right"><button mdbBtn color="default" outline="true" rounded="false" (click)="clear();" *ngIf="result!==0">
        <mdb-icon fas icon="eraser" size="2x"></mdb-icon> Borrar datos
      </button></div>
    </section>

  </mdb-card>

</section>

</div>


