import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-calculadora-oposicions',
  templateUrl: './calculadora-oposicions.component.html',
  styleUrls: ['./calculadora-oposicions.component.scss']
})
export class CalculadoraOposicionsComponent implements OnInit {

  constructor(public fb: FormBuilder) {
    this.gradientForm = fb.group({
      temario: ['', [Validators.required]],
      estudiados: ['', Validators.required],
      bolas: ['', Validators.required],
    });
  }

  result = 0;
  gradientForm: FormGroup;
   resultats = [0,0,0,0,0,0];
   randomThemes = [];

   /*
   Chart
    */
  public chartType: string = 'doughnut';

  public chartDatasets: Array<any> = [
    { data: this.resultats, label: 'My First dataset' }
  ];

  public chartLabels: Array<any> = [];
  public chartLabelsColors: Array<any> = ['#FF5A5E', '#8FC2D7', '#FFC870','#f3963b' ,
    '#A8B3C5', '#616774', '#619d71', '#2b643b',
    '#19cf06'];

  public chartColors: Array<any> = [
    {
      backgroundColor: this.chartLabelsColors,
      hoverBackgroundColor: this.chartLabelsColors,
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true,
    legend: {
        position: 'right',

      labels: {
        fontSize:20,
        padding:20,
        usePointStyle:true

      }

    }

  };

  ngOnInit(): void {
    this.resultats[0]=0;
    this.randomThemes[0]=0;
  }
  getNTemario(){
    return this.gradientForm.get('temario').value;
  }
  getNEstudiados(){
    return this.gradientForm.get('estudiados').value;
  }
  getNBolas(){
    return this.gradientForm.get('bolas').value;
  }

   trunc (x, posiciones = 0) {
    const s = x.toString();
    const l = s.length;
    const decimalLength = s.indexOf('.') + 1;

    if (l - decimalLength <= posiciones){
      return x
    }
    const isNeg  = x < 0
    const decimal =  x % 1
    const entera  = isNeg ? Math.ceil(x) : Math.floor(x)
    const decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    )
    const finalNum = entera +
      ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1));
     return finalNum;
  }

  getRandomThemes(x:number){
    this.randomThemes.splice(0, this.randomThemes.length);
    let i=0;
    let number;
    while (i<x){
      number= this.trunc(Math.random()*this.getNTemario()+1,0);
      if (!this.randomThemes.includes(number)) {
        this.randomThemes.push(number);
        i++;
      }
    }
  }

  calcular(temes, estudiats, bolles, coincidencies, trobades) {
    let result =0;
    if (bolles===0){
      if (trobades===coincidencies){
        return 1;
      }
      else {
        return 0;
      }
      // Encara queden bolles
    }else{
      // Encert
      result=(estudiats/temes)*this.calcular(temes-1,estudiats-1,bolles-1,coincidencies,  trobades++);
      // Errada
      result+=((temes-estudiats)/temes)*this.calcular(temes-1, estudiats, bolles-1, coincidencies, trobades);
    }
    return result;

  }

  calcule() {
    this.resultats.splice(0,this.resultats.length);
    this.chartLabels.splice(0,this.chartLabels.length);
    for (let i=this.getNBolas();i>=0;i--) {
      this.resultats.push(this.trunc(100 * this.calcular(this.getNTemario(), this.getNEstudiados(), this.getNBolas(), i, 0), 2));
      this.chartLabels.push((this.getNBolas() - i) + ' temas (' + this.resultats[this.getNBolas()-i] + '%)');
    }
    this.result=this.trunc(100-this.resultats[0],2);
    this.chartDatasets = [
      { data: this.resultats, label: 'Resultats' }];
    this.getRandomThemes(this.getNBolas());
  }

  clear() {
    this.resultats.splice(0,this.resultats.length);
    this.chartLabels.splice(0,this.chartLabels.length);
    this.result=0;
    this.gradientForm.reset();

  }

  calcRandom() {
    this.getRandomThemes(this.getNBolas());
  }
}
