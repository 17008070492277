import {Component, OnInit} from '@angular/core';
import {Teacher, TEACHERS_MOCK} from '../../model/Teacher';
import {ActivatedRoute} from '@angular/router';
import {Department, DEPARTMENTS_MOCK} from '../../model/Department';
import {ProfessorsService} from '../../services/professors.service';

@Component({
  selector: 'app-detail-professor',
  templateUrl: './detail-professor.component.html',
  styleUrls: ['./detail-professor.component.scss']
})
export class DetailProfessorComponent implements OnInit {

  constructor(private route: ActivatedRoute, private professorsService: ProfessorsService) { }

  professor: Teacher;

  optionsSelect: Department[];
  newDepartaments: Department[];

  selectedValue=-1;
  disabled =true;

  /*
  goBack(): void {
    this.location.back();
  }
*/
  schedule: object[] = [
    { time: '8:00 - 8:55', monday: 'Maths', tuesday: 'Arts', wednesday: 'Geography', thursday: 'Spanish', friday: 'Geography' },
    { time: '8:55 - 9:50', monday: 'Science', tuesday: 'Music', wednesday: 'Geography', thursday: 'Maths', friday: 'History' },
    { time: '9:50 - 10:45', monday: 'English', tuesday: 'Spanish', wednesday: 'Music', thursday: 'Arts', friday: 'Music' },
    { time: '11:05 - 12:00', monday: 'Spanish', tuesday: 'Science', wednesday: 'English', thursday: 'I.T.', friday: 'Science' },
    { time: '12:00 - 12:55', monday: 'I.T.', tuesday: 'Projects', wednesday: 'Science', thursday: 'English', friday: 'Science' },
    { time: '13:05 - 14:00', monday: 'Physic Education', tuesday: 'Math', wednesday: 'Math', thursday: 'Project', friday: 'I.T.' }

  ];


  ngOnInit(): void {

    const teacherIdFromRoute = this.route.snapshot.paramMap.get('professorId');
    // Find the product that correspond with the id provided in route.
    this.professorsService.getProfessor(Number(teacherIdFromRoute)).subscribe(professor => this.professor = professor);
    this.optionsSelect=DEPARTMENTS_MOCK;
  }

  getDepartament(idDpt: number){
    return DEPARTMENTS_MOCK.find(x=>x.id === idDpt).name;
  }


  setNou(){
    console.log(this.selectedValue);
  }

  changeEnableDisable() {
    this.disabled=!this.disabled;
  }
}
