<section class="section team-section">

  <!-- First row -->
  <div class="row">
    <!-- First column -->
    <div class="col-md-12">
      <div class="row mb-1">
        <div class="col-md-4">
          <h3 class="h4-responsive mt-1">Departments</h3>

        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <button type="button" mdbBtn color="default" outline="true" rounded="true" class="waves-light" data-toggle="modal" data-target="#basicexample" (click)="frame.show()" mdbWavesEffect>
            <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>
            Add department</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-1">
          <!-- Tabs -->
          <!-- Nav tabs -->
          <mdb-tabset [buttonClass]="'classic-tabs tabs-primary primary-color'" [contentClass]="'card'" class="classic-tabs">
            <!--Panel 1-->
            <mdb-tab heading="Departaments">
              <!--Panel 1-->
              <div class="tab-pane fade active show" id="panel83" role="tabpanel" aria-expanded="true">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th></th>
                      <th>Department</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!--<tr *ngFor="let dpt of  sortBy('name')"> -->
                    <tr *ngFor="let dpt of  departaments">
                      <th scope="row"><ngx-avatar size="30" value="{{dpt.initials}}"></ngx-avatar></th>
                      <td>{{dpt.name}}</td>
                      <td>
                        <a class="blue-text" mdbTooltip="See members" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
                        <a class="teal-text"  mdbTooltip="Edit" placement="top" [routerLink]="['/departament', dpt.id]"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--/.Panel 1-->
            </mdb-tab>
          </mdb-tabset>
        </div>
      </div>
    </div>
    <!-- /.First column -->
  </div>
</section>
<div mdbModal #frame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" (submit)="newDepartment()">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header text-center justify-content-center">
        <h4 class="heading">New department</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
          <input type="text" id="orangeForm-name" class="form-control" [formControl]="dptFormModalName"
                 mdbInput mdbValidate>
          <label for="orangeForm-name">Name</label>
          <mdb-error *ngIf="dptFormModalName.invalid && (dptFormModalName.dirty || dptFormModalName.touched)">
            Input invalid
          </mdb-error>
          <mdb-success *ngIf="dptFormModalName.valid && (dptFormModalName.dirty || dptFormModalName.touched)">
            Input valid
          </mdb-success>
        </div>
        <div class="md-form mb-5">
          <mdb-icon fas icon="user-circle" class="prefix grey-text"></mdb-icon>
          <input type="text" id="orangeForm-initials" class="form-control" [formControl]="dptFormModalInitials"
                 mdbInput mdbValidate>
          <label for="orangeForm-initials">Initials</label>
          <mdb-error *ngIf="dptFormModalInitials.invalid && (dptFormModalInitials.dirty || dptFormModalInitials.touched)">
            Input invalid
          </mdb-error>
          <mdb-success *ngIf="dptFormModalInitials.valid && (dptFormModalInitials.dirty || dptFormModalInitials.touched)">
            Input valid
          </mdb-success>
        </div>
        <div class="md-form mb-5">
          <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
          <input type="email" id="orangeForm-email" class="form-control" [formControl]="dptFormModalEmail"
                 mdbInput mdbValidate>
          <label for="orangeForm-email">Email</label>
          <mdb-error
            *ngIf="dptFormModalEmail.invalid && (dptFormModalEmail.dirty || dptFormModalEmail.touched)">Input
            invalid
          </mdb-error>
          <mdb-success
            *ngIf="dptFormModalEmail.valid && (dptFormModalEmail.dirty || dptFormModalEmail.touched)">Input
            valid
          </mdb-success>
        </div>
        <div class="md-form mb-5">
          <mdb-icon fas icon="comment" class="prefix grey-text"></mdb-icon>
          <input type="text" id="orangeForm-comments" class="form-control" [formControl]="dptFormModalComments"
                 mdbInput mdbValidate>
          <label for="orangeForm-comments">Comments</label>
          <mdb-error *ngIf="dptFormModalComments.invalid && (dptFormModalComments.dirty || dptFormModalComments.touched)">
            Input invalid
          </mdb-error>
          <mdb-success *ngIf="dptFormModalComments.valid && (dptFormModalComments.dirty || dptFormModalComments.touched)">
            Input valid
          </mdb-success>
        </div>

      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button (click)="clear()"
                mdbBtn color="deep-orange" outline="true" rounded="true" class="waves-light" mdbWavesEffect>Clear</button>

        <button (click)="newDepartment();frame.hide();getDepartments();"
                [disabled]="dptFormModalEmail.invalid || dptFormModalComments.invalid ||
                dptFormModalInitials.invalid || dptFormModalName.invalid"
                mdbBtn color="deep-orange" rounded="true" class="waves-light" mdbWavesEffect>Add department</button>
      </div>
    </div>
  </div>
</div>



