import { Component, OnInit } from '@angular/core';
import {Department, DEPARTMENTS_MOCK} from '../../model/Department';
import {ServerService} from '../../services/server.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-departaments',
  templateUrl: './departaments.component.html',
  styleUrls: ['./departaments.component.scss']
})
export class DepartamentsComponent implements OnInit {

  departaments:  Department[];
  selectedDepartament: Department;
  validatingForm: FormGroup;


  constructor(private server: ServerService) { }

  ngOnInit(): void {
    this.getDepartments();
    this.validatingForm = new FormGroup({
      dptFormModalName: new FormControl('', Validators.required),
      dptFormModalInitials: new FormControl('', Validators.required),
      dptFormModalEmail: new FormControl('', Validators.email),
      dptFormModalComments: new FormControl('', Validators.required),

    });
  }

  getDepartments(){
    this.server.getDepartments().then((response: any) => {
        this.departaments = response.map((d) => {
          d.name=d.name;
          d.id=d.id;
          d.initials=d.initials;
          d.comments= d.comments;
          d.email = d.email;
          d.owner=d.owner;
          return d;
          }
        )
      }
    )
  }

  onSelect(departament: Department): void {
    this.selectedDepartament = departament;
    console.log(this.selectedDepartament);
  }

  sortBy(prop: string) {
    return this.departaments.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  get dptFormModalName() {
    return this.validatingForm.get('dptFormModalName');
  }

  get dptFormModalInitials() {
    return this.validatingForm.get('dptFormModalInitials');
  }

  get dptFormModalEmail() {
    return this.validatingForm.get('dptFormModalEmail');
  }

  get dptFormModalComments() {
    return this.validatingForm.get('dptFormModalComments');
  }

  newDepartment() {
    this.selectedDepartament = new Department();
    console.log(this.dptFormModalInitials.value);
    this.selectedDepartament.initials=this.dptFormModalInitials.value;
    this.selectedDepartament.name=this.dptFormModalName.value;
    this.selectedDepartament.email=this.dptFormModalEmail.value;
    this.selectedDepartament.comments=this.dptFormModalComments.value;
    this.server.createDepartment(this.selectedDepartament);
  }

  clear() {
    this.dptFormModalName.reset();
    this.dptFormModalComments.reset();
    this.dptFormModalInitials.reset();
    this.dptFormModalName.reset();

  }
}
