<div >
  <section class="m-5">

    <!--Card-->
    <mdb-card cascade="true" class="narrower">

      <!--Section: Chart-->
      <section>

        <!--Grid row-->
        <div class="row">

          <!--Grid column-->
          <div class="col mr-0">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header prova lighten-1 text-white">
              <h2 class="h2-responsive mb-0">Número al azar</h2>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="pb-0">
              <div class="view view-cascade gradient-card-header  lighten-1 text-white mt-2 mb-4">

                <mdb-card-body class="row pt-3">
                  <div class="col-12">
                    <form [formGroup]="gradientForm">
                      <div class="md-form">
                        <input type="number" value="1" id="min" formControlName="min" class="form-control" mdbInput>
                        <label for="min">Número mínimo</label>
                      </div>
                      <div class="md-form">
                        <input type="number" value="100" id="max" formControlName="max" class="form-control" mdbInput>
                        <label for="max">Número máximo</label>
                        <mdb-error *ngIf="getNMax()<=getNMin()">El número máximo es mayor que el número mínimo</mdb-error>

                      </div>

                      <div class="md-form">
                        <input type="number"  value="1" id="bolas" max="100" formControlName="bolas" class="form-control" mdbInput>
                        <label for="bolas">Bolas</label>
                        <mdb-error *ngIf="((getNMax()-getNMin()+1)<getNBolas() && !getReemplazo()) || getNBolas()>100">
                          <span *ngIf="(getNMax()-getNMin()+1)<getNBolas() && !getReemplazo()">
                            Debes aumentar los números mínimo y máximo o reducir el número de bolas o activar el reemplazo.
                          </span>
                          <span *ngIf="getNBolas()>100">
                            Has superado el número máximo de bolas (100)
                          </span>

                           </mdb-error>

                      </div>


                      <div class="md-form">

                        <mdb-checkbox formControlName="reemplazo" [checkboxPosition]="'right'" [checked]="true" mdbTooltip="Si está activada, podrán repetirse varios números en el resultado. Una vez ha salido un número vuelve a incluirse en el siguiente sorteo.">Con reemplazo
                          </mdb-checkbox>
                      </div>

                    </form>
                  </div>
                </mdb-card-body>

                <mdb-card-footer class="bg-white border-0" >
                  <button mdbBtn color="default" rounded="false" [disabled]="getNBolas()>100 ||getNMax()<=getNMin() || (getNMax()-getNMin()+1<getNBolas() && !getReemplazo())" (click)="calcule();">Calcular</button>
                </mdb-card-footer>
              </div>
            </mdb-card-body>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-xl-7 col-lg-12 my-0" >

            <!--Card image-->
            <div class="view view-cascade gradient-card-header prova2 mb-4" *ngIf="result">
              <h2 class="h2-responsive mb-1">Ejemplo de sorteo</h2>
            </div>

            <div class="view view-cascade gradient-card-header lighten-1 text-white mb-4" *ngIf="result">

              <div class="text-center d-inline-block justify-content-between align-items-center align-middle" *ngFor="let n of resultats"  >
                <a mdbBtn rounded="true" outline="true" size="lg" gradient="sunny-morning">{{n}} </a>
              </div>
              <div>
                <button mdbBtn color="default" outline="true" rounded="false"  size="4x" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                        (click)="order();">Ordenar</button>
                <button mdbBtn color="default" rounded="false"  size="4x" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                        (click)="calcule();">Resortear</button>

              </div>
            </div>

          </div>
        </div>

        <div class="float-right" >
          <button mdbBtn color="default" outline="true" rounded="false" (click)="clear();" *ngIf="result!==0">
          <mdb-icon fas icon="eraser" size="2x"></mdb-icon> Borrar datos
        </button>
        </div>
      </section>

    </mdb-card>
    <!--/.Card-->

  </section>
</div>


