import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../app.component';
import {OktaAuthService} from '@okta/okta-angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  isAuthenticated: boolean;
  iconsSelect: Array <any>;

  constructor(public oktaAuth: OktaAuthService, public router: Router) {
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );
  }

  async ngOnInit() {
    this.oktaAuth.isAuthenticated().then((auth) => {
      this.isAuthenticated = auth
    });

    this.iconsSelect = [
      {value: '1', label:'1r ESO A', icon:'https://mdbootstrap.com/img/Photos/Avatars/avatar-1.jpg'},
      {value: '2', label:'2n ESO A', icon:'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg'},
      {value: '3', label:'2n ESO B', icon:'https://mdbootstrap.com/img/Photos/Avatars/avatar-3.jpg'},

    ]

    // returns an object with user's claims
   // const userClaims = await this.oktaAuth.getUser();
    // console.log( userClaims.email);
  }

  login (){
    this.oktaAuth.loginRedirect();
  }

  async logout (){
    await this.oktaAuth.logout();
    this.router.navigateByUrl('/');
  }
}
