
<section class="m-5">

  <!--Card-->
  <mdb-card cascade="true" class="narrower">

    <!--Section: Chart-->
    <section>

      <!--Grid row-->
      <div class="row">

        <!--
        PARTICIPANTS
        -->
        <div class="col-lg-4 col-md-6 mb-4">
          <div class="view view-cascade gradient-card-header prova2 lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Participantes</h2>
          </div>
          <div class="row">

            <mdb-card-body  class="pb-0">
              <div class="view view-cascade gradient-card-header  lighten-1  mt-2 mb-4">

                <mdb-card-body class="row pt-3 ">
                  <div class="col-12">
                    <label class="text-black-50" *ngIf="names.length==0">
                      Introduce los partipantes<br> (Pulsar INTRO para añadir un participante).
                    </label>
                    <div [formGroup]="gradientForm">

                    <div class="md-form" (keyup)="keyDownFunction($event)">
                      <input type="text"  formControlName="name" class="form-control" mdbInput>

                    </div>
                    </div>
                    <h2 class="text-black-50">
                      {{names.length}}
                    </h2>
                    <ul class="list-group text-black-50">
                      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let i of names; let j=index">
                        {{i}}
                        <mdb-icon fas icon="minus-circle" (click)="delete(j)"></mdb-icon>

                      </li>
                    </ul>


                  </div>
                </mdb-card-body>
              </div>
            </mdb-card-body>

          </div>
        </div>

        <!--
          LLISTA
        -->
        <div class="col-lg-4 col-md-6 mb-4">


          <!--Card image-->
          <div class="view view-cascade gradient-card-header prova2 lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Premios</h2>
          </div>

          <div class="row">



            <mdb-card-body  class="pb-0">
              <div class="view view-cascade gradient-card-header  lighten-1  mt-2 mb-4">

                <mdb-card-body class="row pt-3 ">
                  <div class="col-12">
                    <label class="text-black-50" *ngIf="awards.length==0" >
                      Introduce los premios<br> (Pulsar INTRO para añadir un premio).
                    </label>
                    <div [formGroup]="gradientForm2">

                      <div class="md-form" (keyup)="keyDownFunctionAwards($event)">
                        <input type="text"  formControlName="award" class="form-control" mdbInput>

                      </div>
                    </div>
                    <h2 class="text-black-50">
                      {{awards.length}}
                    </h2>
                    <ul class="list-group text-black-50">
                      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let i of awards; let j=index">
                        {{i}}
                        <mdb-icon fas icon="minus-circle" (click)="deleteAward(j)"></mdb-icon>
                      </li>
                    </ul>
                  </div>
                </mdb-card-body>
              </div>
            </mdb-card-body>

          </div>
<!--
<!--
            <mdb-card-body  class=" mx-3">
              <div class="gradient-card-header lighten-1  mt-2 mb-4 ">

                <mdb-flipping-card #cards class="pt-0">
                    <div class=" pt-4 face front tp-box_side tp-box_front ">
                      <div class="col-12  pt-3">
                        <label class="text-black-50" *ngIf="awards.length==0" >
                          Introduce los premios<br> (Pulsar INTRO para añadir un premio).
                        </label>
                        <div [formGroup]="gradientForm2">

                          <div class="md-form" (keyup)="keyDownFunctionAwards($event)">
                            <input type="text"  formControlName="award" class="form-control" mdbInput>

                          </div>
                        </div>
                        <h2 class="text-black-50">
                          {{awards.length}}
                        </h2>
                        <ul class="list-group text-black-50">
                          <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let i of awards; let j=index">
                            {{i}}
                            <mdb-icon fas icon="minus-circle" (click)="deleteAward(j)"></mdb-icon>
                          </li>
                        </ul>
                      </div>
                      <a class="rotate-btn" data-card="card-1" (click)="cards.toggle()">
                        <mdb-icon fas icon="redo"></mdb-icon> Click here to rotate</a>
                    </div>

                    <div class="back tp-box_side tp-box_back">

                    &lt;!&ndash;Content&ndash;&gt;
                    <h4>About me</h4>
                    <hr>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime quae, dolores dicta. Blanditiis rem
                      amet repellat,
                      dolores nihil quae in mollitia asperiores ut rerum repellendus, voluptatum eum, officia laudantium
                      quaerat?</p>
                    <hr>
                    &lt;!&ndash;Social Icons&ndash;&gt;
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a class="icons-sm fb-ic">
                          <mdb-icon fab icon="facebook-f"></mdb-icon>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="icons-sm tw-ic">
                          <mdb-icon fab icon="twitter"></mdb-icon>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="icons-sm gplus-ic">
                          <mdb-icon fab icon="google-plus"></mdb-icon>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="icons-sm li-ic">
                          <mdb-icon fab icon="linkedin-in"></mdb-icon>
                        </a>
                      </li>
                    </ul>
                    &lt;!&ndash;Triggering button&ndash;&gt;
                    <a class="rotate-btn" data-card="card-1" (click)="cards.toggle()">
                      <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

                  </div>

                </mdb-flipping-card>
              </div>
            </mdb-card-body>
-->
        </div>

        <div class="col-lg-4 col-md-6 mb-4">

          <!--Card image-->
          <div class="view view-cascade gradient-card-header prova lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Resultado</h2>
          </div>
          <!--/Card image-->

          <!--Card content-->

            <mdb-card-body cascade="true" class="pb-0">
              <div class="view view-cascade gradient-card-header lighten-1 text-reset mt-2 mb-4" [formGroup]="gradientForm3">


                <mdb-card-text>
                  <mdb-checkbox formControlName="reemplazo" [checkboxPosition]="'left'" [checked]="false" [value]="false"   mdbTooltip="Si está desactivada, un jugador no podrá recibir dos premios.">Varios premios al mismo jugador
                  </mdb-checkbox>
                  <br>
                  <mdb-checkbox formControlName="premiosAleatorios" [checkboxPosition]="'bottom'" [checked]="false" [value]="false" mdbTooltip="Si está desactivada, los premios se asignaran en el mismo orden en el cual han sido introducidos.">Orden de premios aleatorio
                  </mdb-checkbox>
                  <br>
                  <mdb-card-footer class="bg-white border-0">
                    <button mdbBtn color="default" rounded="false" (click)="calcule()" >Calcular</button>
                  </mdb-card-footer>

                </mdb-card-text>


                <!--RESULTATS -->
                <mdb-card-body class="row pt-3 " *ngFor="let r of resultatsGuanyadors; index as i">
                  <h3 class="text-prova"><strong>{{resultatsPremis[i]}}: </strong>{{r}}</h3>
                </mdb-card-body>


              </div>
            </mdb-card-body>


        </div>

      </div>

      <div class="float-right"><button mdbBtn color="default" outline="true" rounded="false" (click)="clear();" *ngIf="result!==0">
        <mdb-icon fas icon="eraser" size="2x"></mdb-icon> Borrar datos
      </button></div>
    </section>

  </mdb-card>
  <!--/.Card-->

</section>
