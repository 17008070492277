import { Component, OnInit } from '@angular/core';
import {Teacher} from '../../model/Teacher';
import {Department, DEPARTMENTS_MOCK} from '../../model/Department';
import {ProfessorsService} from '../../services/professors.service';
import {ServerService} from '../../services/server.service';

@Component({
  selector: 'app-professors',
  templateUrl: './professors.component.html',
  styleUrls: ['./professors.component.scss']
})
export class ProfessorsComponent implements OnInit {

  constructor(private professorsService: ProfessorsService, private server: ServerService) {
  }

  professors: Teacher[];
  /*
  addTeacher() {
    const newTeacher = {
      id:100,
      name: 'Bernat',
      surname: 'Ramon',
      idDepartment: 67
    };
    this.server.createTeacher(newTeacher);
    }
    */




  dpts : Department [];


  ngOnInit(): void {
    this.professorsService.getProfessors().subscribe(professors => this.professors = professors);

  }

  getDepartment(idDpt: number) {
    return DEPARTMENTS_MOCK.find(x => x.id === idDpt).name;
  }

  updateDepartment(){
    const newDepartment = {
      id: 55,
      initials: 'IMP',
      name: 'Imatge Personal'
    };
    this.server.updateDepartment(newDepartment);
  }
  addDepartment() {
    const newDepartment = {
      id: 55,
      initials: 'INF',
      name: 'Informàtica.'
    };
    this.server.createDepartment(newDepartment);
  }
  getDepartments(){
    this.server.getDepartments().then((response: any) => {
      // dpts :Department [];
      this.dpts = response.map((d) => {
        d.name = d.name;
        d.initials = d.initials;
        d.id = d.id;
        console.log(d);
        return d;
        }
      )
      }
  )
  }
  addTeacher(){
    const newDepartment = {
      id: 44,
      initials: 'INF',
      name: 'Informàtica.'
    };
    this.server.deleteDepartment(newDepartment);
  }
}
