import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grupos-al-azar',
  templateUrl: './grupos-al-azar.component.html',
  styleUrls: ['./grupos-al-azar.component.scss']
})
export class GruposAlAzarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
