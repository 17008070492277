import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OktaAuthService} from '@okta/okta-angular';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(private http: HttpClient, public oktaAuth: OktaAuthService) {
  }

  private async request(method: string, url: string, data?: any) {
    const token = await this.oktaAuth.getAccessToken();

    const result = this.http.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

 /**
  * DEPARTMENTS
  */
  getDepartments() {
    return this.request('GET', `${environment.serverUrl}/departments`);
  }

  createDepartment(dpt) {
    return this.request('POST', `${environment.serverUrl}/departments`, dpt);
  }

  updateDepartment(dpt) {
    return this.request('PUT', `${environment.serverUrl}/departments/${dpt.id}`, dpt);
  }

  deleteDepartment(dpt) {
    return this.request('DELETE', `${environment.serverUrl}/departments/${dpt.id}`);
  }

  /**
   * TEACHERS
   */
  createTeacher(t) {
    return this.request('POST', `${environment.serverUrl}/teachers`, t);
  }
}
