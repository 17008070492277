<section>

  <!--Grid row-->
  <div class="row">

    <!--Grid column-->
    <div class="col-xl-3 col-md-6 my-4">

      <!--Card-->
      <mdb-card cascade="true" class="cascading-admin-card">

        <!--Card Data-->
        <div class="admin-up">
          <mdb-icon fas icon="calendar-alt" class="primary-color"></mdb-icon>
          <div class="data">
            <p>TODAY</p>
            <h4><strong>{{getDate()}}</strong></h4>
          </div>
        </div>
        <!--/.Card Data-->

      </mdb-card>
      <!--/.Card-->

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-xl-3 col-md-6 my-4">

      <!--Card-->
      <mdb-card cascade="true" class="cascading-admin-card">

        <!--Card Data-->
        <div class="admin-up">
          <mdb-icon fas icon="clock" class="warning-color"></mdb-icon>
          <div class="data">
            <p>TODAY</p>
            <h4><strong>{{getTime()}}</strong></h4>
          </div>
        </div>
        <!--/.Card Data-->
      </mdb-card>
      <!--/.Card-->

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-xl-3 col-md-6 my-4">

      <!--Card-->
      <mdb-card cascade="true" class="cascading-admin-card">

        <!--Card Data-->
        <div class="admin-up">
          <mdb-icon fas icon="users" class="light-blue lighten-1"></mdb-icon>
          <div class="data">
            <p>TEACHERS</p>
            <h4><strong>{{getNumberOfTeachers()}}</strong></h4>
          </div>
        </div>
        <!--/.Card Data-->

      </mdb-card>
      <!--/.Card-->

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-xl-3 col-md-6 my-4">

      <!--Card-->
      <mdb-card cascade="true" class="cascading-admin-card">

        <!--Card Data-->
        <div class="admin-up">
          <mdb-icon fas icon="cogs" class="red accent-2" aria-disabled="true"></mdb-icon>
          <div class="data">
            <p>GO TO GENERATE SCHEDULE</p>
            <h4 aria-disabled="true"><strong>Coming soon</strong></h4>
          </div>
        </div>
        <!--/.Card Data-->

      </mdb-card>
      <!--/.Card-->

    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

</section>
