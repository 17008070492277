
<section class="m-5">

  <!--Card-->
  <mdb-card cascade="true" class="narrower">

    <!--Section: Chart-->
    <section>

      <!--Grid row-->
      <div class="row">

        <!--
        PARTICIPANTS
        -->
        <div class="col-lg-4 col-md-6 mb-4">
          <div class="view view-cascade gradient-card-header prova2 lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Participantes</h2>
          </div>
          <div class="row">

            <mdb-card-body  class="pb-0">
              <div class="view view-cascade gradient-card-header  lighten-1  mt-2 mb-4">

                <mdb-card-body class="row pt-3 ">
                  <div class="col-12">
                    <div [formGroup]="gradientForm">

                      <div class="md-form" (keyup)="keyDownFunction($event)">
                        <input type="text" id="name" formControlName="name" class="form-control" mdbInput>
                        <label for="name">Introduce los participantes</label>
                      </div>
                    </div>
                    <h2 class="text-black-50">
                      {{names.length}}
                    </h2>
                    <ul class="list-group text-black-50">
                      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let i of names; let j=index">
                        {{i}}
                        <mdb-icon fas icon="minus-circle" (click)="delete(j)"></mdb-icon>

                      </li>
                    </ul>


                  </div>
                </mdb-card-body>
              </div>
            </mdb-card-body>

          </div>
        </div>

        <!--
          LLISTA
        -->
        <div class="col-lg-4 col-md-6 mb-4">


          <!--Card image-->
          <div class="view view-cascade gradient-card-header prova2 lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Condiciones</h2>
          </div>

          <div class="row">



            <mdb-card-body  class="pb-0">
              <div class="view view-cascade gradient-card-header  lighten-1  mt-2 mb-4">

                <mdb-card-body class="row pt-3 ">
                  <div class="col-12">

                    <div [formGroup]="gradientForm2">
                      <div class="md-form">
                        <input type="number" id="nAwards" [value]="1"  min="1"   formControlName="nAwards" class="form-control" mdbInput>
                        <label for="nAwards">Número de premios</label>
                      </div>

                      <div class="md-form">
                        <mdb-checkbox formControlName="reemplazo" [checkboxPosition]="'left'" [checked]="false" value="false"   mdbTooltip="Si esta casilla está desactivada, un jugador no podrá recibir varios premios.">Varios premios al mismo jugador
                        </mdb-checkbox>
                      </div>
                      <div class="md-form">
                        <button mdbBtn color="default" rounded="false"  (click)="calcule()" >Calcular</button>
                      </div>
                      <div class="md-form">
                        <mdb-error *ngIf="names.length>3">Has superado el número máximo de bolas que permite la aplicación (10)</mdb-error>
                      </div>
                      </div>
                  </div>
                </mdb-card-body>
              </div>
            </mdb-card-body>

          </div>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">

          <!--Card image-->
          <div class="view view-cascade gradient-card-header prova lighten-1 text-white">
            <h2 class="h2-responsive mb-0">Resultado</h2>
          </div>

          <mdb-card-body cascade="true" class="pb-0" *ngIf="resultatsGuanyadors.length!=0">
            <div class="view view-cascade gradient-card-header lighten-1 text-reset mt-2 mb-4">


              <!--RESULTATS -->
              <mdb-card-body class="row pt-3 " *ngFor="let r of resultatsGuanyadors; index as i">
                <h3 class="text-prova">Premio {{i+1}}: <strong>{{r}}</strong></h3>
              </mdb-card-body>


            </div>
          </mdb-card-body>


        </div>

      </div>

      <div class="float-right"><button mdbBtn color="default" outline="true" rounded="false" (click)="clear();">
        <mdb-icon fas icon="eraser" size="2x"></mdb-icon> Borrar datos
      </button></div>
    </section>

  </mdb-card>
  <!--/.Card-->

</section>
